import BaseQueryRepositoryInterface from '@/core/domain/repository/BaseQueryRepositoryInterface'

export default class BasePluginQuery {
  private repository: BaseQueryRepositoryInterface;
  private readonly plugin: string;

  constructor (plugin: string, repository: BaseQueryRepositoryInterface) {
    this.plugin = plugin
    this.repository = repository
  }

  execute (payload: object = {}): Promise<any> {
    return this.repository.get(this.plugin, payload)
  }
}
